.united-page {
  .hero {
    background-image: url('#{$imgPath}united/hero_d.jpg');
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}united/hero_m.jpg');
    }
  }
  img.icon-points {
    width: 34px;
    height: 34px;
  }
  img.icon-miles {
    width: 28px;
    height: 29px;
  }
  img.icon-status {
    width: 19px;
    height: 23px;
  }
  img.icon-calendar {
    width: 25px;
    height: 24px;
  }
  img.icon-lightning-bolt {
    width: 19px;
    height: 29px;
  }
  .cards {
    @include flex-grid(4, 17px);
    @media #{$mxw-lg} {
      @include flex-grid(2, 17px);
    }
    @media #{$mxw-sm} {
      @include flex-grid(1, 12px);
    }
  }
}

.elite-offer-lto {
  position: relative;
  background: linear-gradient(70deg, #B7755F, #F6BAA1);
  padding: 2px;
  max-width: 1010px;
  margin: 0 auto 55px;
  @media (max-width: #{$swiper-1-bp - 1}) {
    margin: 50px auto 55px;
  }
  @media #{$mxw-sm} {
    margin: 50px auto 35px;
  }
  .logo {
    position: absolute;
    padding: 0 23px;
    background-color: #fff;
    top: -22px;
    left: 50%;
    transform: translateX(-50%);
    img {
      width: 38px;
      height: 44px;
    }
  }
  .wrap {
    background-color: #fff;
    padding: 60px 60px 30px;
    @media #{$mxw-sm} {
      padding: 60px 30px 30px;
    }
    @media #{$mxw-xs} {
      padding: 50px 20px 30px;
    }
  }
  .links {
    @media (max-width: 400px) {
      flex-direction: column;
      a {
        &:last-of-type {
          margin: 10px 0 0;
        }
        &:after {
          display: none;
        }
      }
    }
  }
}

#united-landing-page {
  .img-wrap {
    max-width: 1028px;
    margin: 0 auto;
  }
  .modules-subheading-1 {
    margin-bottom: 50px;
    @media (max-width: #{$swiper-1-bp - 1}) {
      margin-bottom: 20px;
    }
  }
  .modules-subheading-2 {
    margin-bottom: 50px;
    @media (max-width: #{$swiper-2-bp - 1}) {
      margin-bottom: 20px;
    }
  }
  .modules {
    margin-bottom: 70px;
    @media #{$mxw-sm} {
      margin-bottom: 30px;
    }
    &.desktop {
      display: flex;
      justify-content: center;
      .module {
        margin-right: 25px;
        &:last-of-type {
          margin-right: 0;
        }
      }
    }
  }
  .modules-1.desktop {
    @media (max-width: #{$swiper-1-bp - 1}) {
      display: none;
    }
  }
  .modules-2.desktop {
    @media (max-width: #{$swiper-2-bp - 1}) {
      display: none;
    }
  }
  .swiper-outer-wrap-1 {
    display: none;
    @media (max-width: #{$swiper-1-bp - 1}) {
      display: block;
    }
  }
  .swiper-outer-wrap-2 {
    display: none;
    @media (max-width: #{$swiper-2-bp - 1}) {
      display: block;
    }
  }
  .module {
    width: 250px;
    text-align: center;
    margin: 0 12px;
    .heading-wrap {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      background-color: $bonvoy-white;
      height: 75px;
      margin-bottom: 20px;
    }
    img.icon {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
    img.icon-status {
      top: -10px;
    }
    img.icon-miles {
      top: -16px;
    }
    img.icon-points {
      top: -15px;
    }
    img.icon-calendar {
      top: -10px;
    }
    img.icon-lightning-bolt {
      top: -14px;
    }
  }
  .modal-dialog {
    @media #{$mxw-sm} {
      margin-top: 0;
      margin-right: 0;
      margin-left: 0;
    }
  }
  .modal {
    @media #{$mxw-md} {
      .mxw-md {
        width: 100%;
      }
    }
    .table-heading.mbv {
      background-color: $coral-lt;
    }
    .table-heading.united {
      background-color: $united;
    }
    .table-heading {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 18px 16px 25px;
      @media #{$mxw-sm} {
        flex-direction: column-reverse;
        justify-content: center;
        text-align: center;
      }
      .heading {
        font-family: $font-swiss-light;
        font-size: 20px;
        margin-bottom: 0;
        @media #{$mxw-sm} {
          margin-top: 15px;
        }
      }
    }
    .logo-mbv-black {
      width: 124px;
    }
    .logo-united-white {
      width: 136px;
    }
    tr:first-of-type {
      td:first-of-type {
        border-right: 1px solid $gray;
      }
    }
    tr:last-of-type {
      th,
      td {
        border-bottom: none;
      }
    }
    th,
    td {
      padding: 15px;
      @media #{$mxw-sm} {
        font-size: 14px;
      }
      &:first-of-type {
        width: 218px;
        @media #{$mxw-sm} {
          width: 33%;
        }
      }
      &:nth-of-type(2) {
        width: 278px;
        @media #{$mxw-sm} {
          width: 33%;
        }
      }
      &:last-of-type {
        width: 233px;
        @media #{$mxw-sm} {
          width: 33%;
        }
      }
    }
    th {
      text-align: center;
      border-bottom: 1px solid $gray;
      border-right: 1px solid $gray;
      &:last-of-type {
        border-right: none;
      }
    }
    th[scope="row"] {
      text-align: right;
      border-right: 1px solid $gray;
    }
    td {
      position: relative;
      border-bottom: 1px solid $gray;
      border-right: 1px solid $gray;
      &:first-of-type {
        text-align: right;
      }
      &:last-of-type {
        border-right: none;
      }
    }
    .dot {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 18px;
      height: 18px;
      border-radius: 50%;
    }
    .mbv {
      border: 2px solid $coral-lt;
      .dot {
        background-color: $bonvoy;
      }
    }
    .united {
      border: 2px solid $united;
      .dot {
        background-color: $united;
      }
    }
    @media #{$mxw-sm} {
      .mbv,
      .united {
        border: none;
      }
    }
  }
} // #united-landing-page

#united-success-page {
  .elite-offer-lto {
    margin: 60px auto 0;
  }
}

.united-promo-page {
  .hero {
    height: 312px;
    background-image: url('#{$imgPath}united-promo/hero_d.jpg');
    @media #{$mxw-md} {
      height: 203px;
      background-image: url('#{$imgPath}united-promo/hero_m.jpg');
    }
  }
  .logo {
    width: 330px;
    @media #{$mxw-md} {
      width: 270px;
    }
  }
  .promo-miles-points-both {
    .flex-grid {
      @include flex-grid(2, 25px);
      @media #{$mxw-md} {
        @include flex-grid(1, 0px);
      }
      &.desktop {
        @media (max-width: 475px) {
          display: none;
        }
      }
    }
    .flex-grid,
    .box {
      max-width: 760px;
      margin: 0 auto;
      @media #{$mxw-md} {
        max-width: 400px;
      }
    }
    .module {
      position: relative;
      display: flex;
      flex-direction: column;
      @media #{$mxw-md} {
        margin-bottom: 70px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .img-wrap {
      position: relative;
      [class*='heading'] {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        width: 100%;
        top: -20px;
        left: 50%;
        transform: translateX(-50%);
        margin: 0;
        > * {
          padding: 8px 20px;
          background-color: #fff;
          border: 2px solid $color-1;
        }
      }
      .caption {
        position: absolute;
        bottom: 8px;
        width: 100%;
        color: #fff;
        font-size: 12px;
        padding: 0 15px;
      }
    }
    .copy-wrap {
      margin-top: 30px;
      text-align: center;
      padding: 0 40px;
      @media #{$mxw-md} {
        padding: 0 20px;
      }
      @media #{$mxw-sm} {
        padding: 0 10px;
      }
    }
    .box {
      position: relative;
      border-color: $color-1;
      [class*='heading'] {
        font-size: 26px;
        position: absolute;
        top: -25px;
        left: 50%;
        transform: translateX(-50%);
        text-transform: uppercase;
        padding: 8px 20px;
        background-color: #fff;
        border: 2px solid $color-1;
      }
      .icons-wrap {
        img {
          width: 24px;
        }
        p {
          color: $gray-dk;
          font-family: $font-main-bold;
        }
      }
      .body {
        font-size: 18px;
        @media #{$mxw-md} {
          font-size: 16px;
        }
      }
    }
  } // .promo-miles-points-both
} // .united-promo-page

#united-promo-landing-page {
  .reg {
    display: none;
  }
  .mobile {
    display: none;
    .img-wrap {
      flex: 0 0 40%;
    }
    @media (max-width: 475px) {
      display: block;
    }
    @media #{$mxw-xxs} {
      .copy-wrap p {
        font-size: 14px;
      }
    }
  }
  .fz-lg {
    @media #{$mxw-md} {
      font-size: 20px !important;
    }
  }
} // #united-promo-landing-page

#united-promo-landing-page,
#united-promo-registration-page {
  .divider {
    position: relative;
    height: 44px;
    &:before,
    &:after {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      z-index: 9;
    }
    &:before {
      content: '';
      width: 44px;
      height: 44px;
      background-color: #fff;
      border: 2px solid $color-1;
      border-radius: 50%;
    }
    &:after {
      content: '+';
      font-family: $font-swiss-medium;
      font-size: 30px;
    }
    .line {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 100%;
      background-color: $color-1;
    }
  }
}

#united-promo-registration-page {
  .desktop {
    @media #{$mxw-sm} {
      display: none;
    }
    .divider {
      position: absolute;
      top: 14px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  .mobile {
    display: none;
    @media #{$mxw-sm} {
      display: flex;
      flex-direction: column;
    }
    .subheading-2 {
      border: none;
    }
  }
}

#united-promo-terms-page {
  li {
    padding-bottom: 20px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}

.united-lto-banner {
  max-width: 750px;
  margin: 0 auto;
  border-radius: 10px;
  box-shadow: $box-shadow-2;
  overflow: hidden;
  .img-flag .wrap {
    border-bottom: 33px solid #fff;
    border-left: 33px solid transparent;
    border-right: 33px solid transparent;
    width: 250px;
    p {
      font-size: 16px;
    }
  }
  [class*='heading'] {
    font-size: 26px;
    @media #{$mxw-md} {
      font-size: 18px;
    }
  }
  .copy-wrap {
    padding: 40px 60px 45px;
    @media #{$mxw-md} {
      padding: 35px 25px 40px;
    }
    p {
      font-size: 21px;
      @media #{$mxw-md} {
        font-size: 16px;
      }
    }
  }
}
