.zh-cn {
  #app {
    font-weight: normal;
  }
  .link-1,
  .bold {
    font-weight: bold;
  }
  .bolder {
    font-weight: bolder;
  }
}

.fr-ca {
  #app {
    #air-canada-not-eligible-page .elevate {
      @media #{$mnw-lg} {
        padding: 33px 150px 36px;
      }
    }
    .air-canada-page .benefits .checklist .item {
      flex: 0 0 30%;
      width: 30%;
    }
  }
}

.ar-ae #app #global-landing-page {
  .link-arrow {
    margin-left: 0px;
    margin-right: 20px;
    text-transform: unset;
    &:before {
      top: -4px;
      left: unset;
      right: -23px;
      transform: rotate(180deg) scale(0.5);
    }
  }
  input[type="radio"] {
    margin: 0 0 0 10px;
  }

  #section-1 {
    .img-wrap {
      margin-left: 40px;
      margin-right: 0;
    }
    .flex-row-reverse {
      .img-wrap {
        margin-left: 0;
        margin-right: 40px;
      }
      .copy-wrap {
        max-width: unset;
      }
    }
    .copy-wrap {
      text-align: right;
    }
    .swiper {
      @media (max-width: 479px) {
        margin-left: 0;
        margin-right: 12px;
      }
    }
  }
  // #section-2 {
  //   .module-1 {
  //     grid-column-start: 4;
  //     grid-column-end: 6;
  //   }
  //   .module-2 {
  //     grid-column-start: 2;
  //     grid-column-end: 4;
  //   }
  //   .module-3 {
  //     grid-column-start: 5;
  //     grid-column-end: 7;
  //   }
  //   .module-4 {
  //     grid-column-start: 3;
  //     grid-column-end: 5;
  //   }
  //   .module-5 {
  //     grid-column-start: 1;
  //     grid-column-end: 3;
  //   }
  // }
  .calculator-points-miles .calculator {
    .col-right {
      margin: 0 25px 0;
      text-align: right !important;
    }
    .row-mobile {
      .left-side {
        margin: 0 0 0 20px;
      }
      .label {
        margin: 0 0 5px 12px;
      }
    }
    .dropdown-menu button {
      text-align: right;
    }
  }
}
