.air-canada-page {
  .hero {
    background-image: url('#{$imgPath}air-canada/hero_d.jpg');
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}air-canada/hero_m.jpg');
    }
    .logo {
      bottom: 42px;
      width: 539px;
      @media #{$mxw-md} {
        width: 316px;
      }
      @media #{$mxw-xxs} {
        width: 85%;
      }
    }
  }
  .terms-faqs-wrap {
    @include terms-faqs-wrap;
  }
  .heading-pill {
    display: inline-block;
    font-family: $font-aldine-italic;
    border: 1px solid $coral;
    border-radius: 20px;
    padding: 8px 20px;
    background-color: #fff;
    .wrap {
      display: flex;
      justify-content: center;
      > * {
        flex: 0 0 auto;
      }
    }
    img {
      width: 17px;
      height: 20px;
      margin-right: 10px;
    }
  }
  .benefits {
    max-width: 800px;
    margin: 0 auto;
    .checklist {
      display: flex;
      justify-content: center;
      max-width: 530px;
      margin: 0 auto;
      @media #{$mxw-sm} {
        flex-direction: column;
        max-width: 245px;
      }
      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 22px;
        @media #{$mxw-md} {
          margin: 0 12px;
        }
        @media #{$mxw-sm} {
          flex-direction: row;
          margin-right: 0;
          margin-bottom: 26px;
          &:last-of-type {
            margin-bottom: 0;
          }
          p {
            text-align: left;
          }
        }
      }
      img {
        width: 37px;
      }
    }
  }
} // .air-canada-page

.air-canada-promo-page {
  .hero {
    position: relative;
    background-image: url('#{$imgPath}air-canada/hero-promo_d.jpg');
    height: 412px;
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}air-canada/hero-promo_m.jpg');
    }
    @media #{$mxw-sm} {
      height: 217px;
    }
    .logo {
      bottom: 60px;
      @media #{$mxw-md} {
        bottom: 32px;
      }
    }
    .heading-pill {
      position: absolute;
      bottom: -23px;
      left: 50%;
      transform: translateX(-50%);
      border-radius: 22px;
      img {
        width: 21px;
        height: 25px;
      }
      p {
        font-size: 20px;
      }
    }
  }
  .promo-animations {
    img {
      width: 130px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
    .item-1 {
      margin-right: 25px;
    }
    .item-2 {
      margin-left: 25px;
    }
    @media #{$mxw-xxs} {
      .item-1 {
        margin-right: 15px;
      }
      .item-2 {
        margin-left: 15px;
      }
    }
  }
}

#air-canada-landing-page {
  .lto-section {
    position: relative;
    max-width: 910px;
    margin: 0 auto;
    border: 1px solid $coral;
    border-radius: 20px;
    padding: 42px 88px 16px;
    @media #{$mxw-sm} {
      padding: 42px 65px 16px;
    }
    @media #{$mxw-xs} {
      padding: 42px 42px 16px;
    }
    .icon-img {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: -28px;
      left: 50%;
      transform: translateX(-50%);
      width: 56px;
      height: 56px;
      border: 1px solid $coral;
      border-radius: 50%;
      background-color: #fff;
      img {
        width: 29px;
        height: 34px;
      }
    }
    .body {
      font-size: 21px;
      @media #{$mxw-md} {
        font-size: 16px;
      }
    }
  } // .lto-section
  .section-4 {
    .modules-section {
      background-image: url('#{$imgPath}air-canada/landing-benefits-bg_d.jpg');
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      height: 656px;
      @media #{$mxw-md} {
        background-image: url('#{$imgPath}air-canada/landing-benefits-bg_m.jpg');
        height: 932px;
      }
    }
    .modules {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .flex-grid {
      @include flex-grid(2, 50px);
      @media #{$mxw-md} {
        @include flex-grid(1, 32px);
        flex-direction: column;
      }
    }
    .module {
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      max-width: 375px;
      text-align: center;
      background-color: #fff;
      border-radius: 20px;
      box-shadow: $box-shadow;
      .heading-section {
        padding: 40px 30px 34px;
        background-color: $bonvoy-white;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
      }
      .body-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex: 1 1 auto;
        padding: 34px 37px;
      }
      hr {
        border-top: 1px solid $coral;
      }
    }
    .module-1 {
      margin-right: 50px;
      img {
        width: 19px;
      }
    }
    .module-2 {
      img {
        width: 26px;
      }
      .body-section {
        padding: 34px 96px;
        @media #{$mxw-xs} {
          padding: 34px 37px;
        }
      }
    }
  }
} // #air-canada-landing-page

#air-canada-not-eligible-page {
  .elevate {
    max-width: 830px;
    margin: 0 auto;
    border: 1px solid $coral;
    border-radius: 20px;
    padding: 33px 160px 36px;
    text-align: center;
    @media #{$mxw-lg} {
      padding: 33px 130px 36px;
    }
    @media #{$mxw-md} {
      padding: 33px 100px 36px;
    }
    @media #{$mxw-sm} {
      padding: 25px 25px 36px;
    }
  }
}

#air-canada-success-page {
  .lto-module {
    max-width: 800px;
    margin: 0 auto;
    border-radius: 20px;
    box-shadow: 0 0 4px rgba(#000, 0.5);
    .img-wrap {
      border-bottom: 1px solid $coral;
    }
    .body-wrap {
      position: relative;
      padding: 40px 195px 25px;
      @media #{$mxw-lg} {
        padding: 40px 150px 25px;
      }
      @media #{$mxw-md} {
        padding: 40px 100px 25px;
      }
      @media #{$mxw-sm} {
        padding: 40px 40px 25px;
      }
    }
    .heading-pill {
      position: absolute;
      top: -20px;
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

#air-canada-terms-page {
  section {
    .section-heading {
      margin-top: 30px;
    }
    &:first-of-type .section-heading {
      margin-top: 0;
    }
  }
  li {
    padding-bottom: 10px;
    &:last-of-type {
      padding-bottom: 0;
    }
  }
}
