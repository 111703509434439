.singapore-page {
  .hero {
    background-image: url('#{$imgPath}singapore/hero_d.jpg');
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}singapore/hero_m.jpg');
    }
    .logo {
      bottom: 20px;
    }
  }

  .dropdown-toggle,
  .dropdown-menu {
    border-radius: 9px;
  }
  .dropdown-toggle {
    box-shadow: 0 0 4px rgba(#000, 0.33);
  }
  .dropdown-menu {
    padding-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    .option {
      padding: 6px 12px;
      &:hover {
        font-family: $font-swiss-medium;
      }
    }
  }
  .no-selection {
    border: 1px dashed $gray;
    padding: 60px;
    text-transform: uppercase;
  }
  .benefit-cards-amount-1 {
    max-width: 401px;
  }
  .benefit-cards-amount-2,
  .benefit-cards-amount-4 {
    @include flex-grid(2, 80px);
    max-width: 885px;
    @media #{$mxw-md} {
      @include flex-grid(2, 60px);
    }
    @media #{$mxw-sm} {
      @include flex-grid(1, 0px);
      max-width: 401px;
      > * {
        margin-bottom: 80px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }
  .benefit-card {
    padding: 35px 18px 30px;
    .subheading {
      padding: 0;
    }
  }
  .plus {
    display: flex;
    justify-content: center;
    align-items: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $color-1;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    > * {
      position: relative;
      bottom: 1px;
      font-family: $font-swiss-medium;
      font-size: 18px;
    }
    @media #{$mxw-sm} {
      top: unset;
      bottom: -68px;
    }
  }
  .additional-benefits {
    .flex-grid {
      @include flex-grid(3, 20px);
      @media #{$mxw-md} {
        @include flex-grid(1, 0px);
        max-width: 500px;
        margin: 0 auto;
      }
    }
    .module {
      @media #{$mxw-md} {
        margin-bottom: 60px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
    .img-wrap {
      position: relative;
      border-radius: 11px;
      overflow: hidden;
      .caption {
        position: absolute;
        bottom: 10px;
        left: 50%;
        transform: translateX(-50%);
        width: 95%;
        color: #fff;
        font-family: $font-swiss-regular;
        font-size: 12px;
        text-align: center;
        letter-spacing: 0.67px;
      }
    }
    .subheading-2 {
      a {
        color: $black;
        &:hover {
          color: $black;
        }
      }
    }
  }
  .box {
    @media #{$mnw-lg} {
      padding: 33px 120px 36px;
    }
    @media #{$mnw-md} {
      padding: 33px 70px 36px;
    }
  }
  .lto-module {
    max-width: 750px;
    margin: 0 auto;
    border-radius: 9px;
    box-shadow: 0 0 4px rgba(#000, 0.5);
    overflow: hidden;
    @media #{$mxw-md} {
      max-width: 450px;
    }
    .header {
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $color-1;
      padding: 16px 20px 18px;
      @media #{$mxw-md} {
        padding: 12px 20px 15px;
      }
      .heading {
        font-size: 26px;
        @media #{$mxw-md} {
          font-size: 24px;
        }
      }
      img {
        width: 40px;
      }
    }
    .content-wrap {
      display: flex;
      > * {
        flex: 0 0 50%;
        width: 50%;
      }
      @media #{$mxw-md} {
        flex-direction: column;
        > * {
          flex: 0 0 100%;
          width: 100%;
        }
      }
    }
    .img-wrap {
      background-image: url('#{$imgPath}singapore/img-lto_d.jpg');
      background-size: cover;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      @media #{$mxw-md} {
        background-image: none;
      }
    }
    .copy-wrap {
      line-height: 1.25;
      padding: 20px;
      .value {
        font-family: $font-aldine-light;
        font-size: 60px;
      }
    }
  }
} // .singapore-page

#singapore-landing-page {
  .dropdown button.dropdown-toggle > span {
    font-size: 14px;
    &.placeholder {
      text-transform: uppercase;
      font-size: 12px;
      letter-spacing: 0.85px;
    }
  }
  .no-link {
    display: inline;
  }
  .hide-on-landing {
    display: none;
  }
  .benefit-cards .front .subheading a {
    display: none;
  }
}

#singapore-faqs-page,
#singapore-terms-page {
  table {
    width: 100%;
    td {
      border: 1px solid $gray;
      padding: 8px 14px;
    }
  }
  .table-1 {
    td:first-of-type {
      width: 59%;
    }
  }
}
