#global-landing-page {
  .hero {
    background-image: url('#{$imgPath}global/hero_d.jpg');
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}global/hero_m.jpg');
    }
    .copy {
      display: inline-block;
      max-width: 705px;
      background-color: rgba(#fff, 85%);
      text-align: center;
      padding: 30px 90px;
      @media #{$mxw-md} {
        padding: 20px;
        max-width: 500px;
      }
    }
  }
  #section-1 {
    $section-1_bp: 680px;
    .desktop {
      @media (max-width: $section-1_bp) {
        display: none;
      }
    }
    .mobile {
      display: none;
      @media (max-width: $section-1_bp) {
        display: block;
      }
    }
    .module {
      border-bottom: 1px solid $gray;
      &:last-of-type {
        border: none;
      }
    }
    .flex-row-reverse {
      .img-wrap {
        margin-right: 0;
        margin-left: 40px;
      }
      .copy-wrap {
        text-align: right;
      }
    }
    .img-wrap {
      margin-right: 40px;
      img {
        width: 380px;
        border-radius: 10px;
      }
    }
    .copy-wrap {
      max-width: 460px;
      // .link-1 {
      //   white-space: nowrap;
      // }
      a:not(.link-1) {
        color: $black;
      }
      img {
        width: 36px;
      }
    }
    .swiper {
      height: 100%;
      .img-wrap {
        padding: 20px 35px 15px;
        margin: 0;
        img {
          width: 100%;
          border-radius: 0;
        }
      }
      .copy-wrap {
        padding: 0 15px 30px;
      }
      .module {
        width: 300px;
        border: none;
        box-shadow: $box-shadow-2;
        border-radius: 9px;
        @media (max-width: 360px) {
          width: 85%;
        }
      }
      @media (max-width: 479px) {
        margin-left: 12px;
      }
    }
    .swiper-slide {
      height: auto !important;
    }
    .swiper-outer-wrap {
      padding: 0 5px 40px;
    }
    .swiper-pagination {
      top: unset;
      bottom: -40px;
      @media (max-width: 479px) {
        transform: translateX(-6px);
      }
    }

    /* flip the picture when hover */
    .flip-container:hover .flipper,
    .flip-container.hover .flipper {
      @media (max-width: $section-1_bp) {
        -webkit-transform: none;
        transform: none;
      }
    }
    /* flip speed */
    .flipper {
      @media (max-width: $section-1_bp) {
        &.flip {
          transform: rotateY(180deg);
        }
      }
    }
  } // #section-1

  #section-2 {
    border-top: 3px solid $color-1;
    border-bottom: 3px solid $color-1;
    .modules {
      @include flex-grid(3, 20px);
      justify-content: center;
      .module {
        max-width: 377px;
      }
    }
    .modules-2 {
      @include flex-grid(2, 20px);
      @media #{$mxw-md} {
        @include section-2-responsive;
      }
    }
    .modules-4 {
      @include flex-grid(2, 20px);
      max-width: 800px;
      @media #{$mxw-md} {
        @include section-2-responsive;
      }
    }
    .modules-3,
    .modules-5,
    .modules-6 {
      @media #{$mxw-lg} {
        @include section-2-responsive;
      }
    }
    .modules-5 {
      display: grid;
      grid-template-columns: repeat(7, auto);
      grid-template-rows: 1fr 1fr;
      gap: 20px;
      > * {
        margin: unset !important;
        width: auto;
      }
      .module-1,
      .module-2 {
        grid-row-start: 1;
        grid-row-end: 2;
      }
      .module-3,
      .module-4,
      .module-5 {
        grid-row-start: 2;
        grid-row-end: 3;
      }
      .module-1 {
        grid-column-start: 2;
        grid-column-end: 4;
      }
      .module-2 {
        grid-column-start: 4;
        grid-column-end: 6;
      }
      .module-3 {
        grid-column-start: 1;
        grid-column-end: 3;
      }
      .module-4 {
        grid-column-start: 3;
        grid-column-end: 5;
      }
      .module-5 {
        grid-column-start: 5;
        grid-column-end: 7;
      }
      @media #{$mxw-lg} {
        @include section-2-responsive;
      }
    }
    .module {
      position: relative;
      border-radius: 8px;
      border: none;
      box-shadow: $box-shadow-2;
      overflow: hidden;
      img.logo {
        position: absolute;
        bottom: 45px;
        right: 20px;
        @media #{$mxw-xs} {
          bottom: 55px;
          right: 15px;
        }
      }
      .wrap {
        text-align: left;
        background-color: #fff;
        padding: 25px 30px;
      }
      &:hover {
        box-shadow: $box-shadow-2-hov;
      }
      &.module-UA img.logo {
        background-color: #fff;
        border: 1px solid $gray-10;
      }
    }
  } // .section-2

  .calculator-points-miles {
    .conversion-type {
      .input-wrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        label {
          font-family: $font-swiss-medium;
          font-size: 18px;
          @media #{$mxw-sm} {
            font-size: 16px;
          }
        }
      }
    }
    .input-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      &.range .asterisk {
        display: none;
      }
    }
    input[type='radio'] {
      width: 16px;
      height: 16px;
      &:before {
        width: 10px;
        height: 10px;
      }
    }
    .calculator {
      font-size: 18px;
      .row-1 {
        flex: 1 1 auto;
        @media #{$mxw-sm} {
          .label {
            width: 100%;
            text-align: center;
          }
        }
      }
      .label {
        margin-bottom: 5px;
      }
      .col-left {
        flex: 1 1 auto;
      }
      .col-right {
        flex: 0 0 100px;
        margin-left: 25px;
      }
      .ratio {
        p {
          padding: 11px;
        }
      }
      .range-slider-wrap-el {
        top: 8px;
      }
      input[type='text'] {
        border-radius: 0;
      }
      input[type='range'] {
        height: 44px;
      }
      input[type='text'],
      input[type='range'] {
        &:disabled {
          cursor: not-allowed;
        }
      }
      .marker {
        position: absolute;
        width: 1px;
        height: 12px;
        top: 50%;
        transform: translateY(-50%);
        background-color: $black;
        pointer-events: none;
        span {
          position: absolute;
          bottom: 18px;
          left: 50%;
          transform: translateX(-50%);
          font-size: 12px;
          margin-top: 10px;
        }
      }
      .subtotal {
        padding: 8px 20px 8px 10px;
        @media #{$mxw-xs} {
          padding: 8px 10px;
        }
      }
      .total {
        padding: 10px 20px;
        border: 1px solid $coral;
      }
      .row-mobile {
        justify-content: center;
        .label {
          margin-right: 12px;
        }
        input[type='text'],
        .ratio {
          max-width: 100px;
        }
        .ratio {
          min-width: 50px;
          > * {
            padding: 11px 30px 11px 15px;
          }
        }
        .left-side {
          margin-right: 20px;
        }
        .right-side {
          margin-left: 20px;
        }
        @media #{$mxw-xs} {
          justify-content: space-between;
          .left-side {
            margin-right: 10px;
          }
          .right-side {
            margin-left: 10px;
          }
        }
      }
      @media #{$mxw-xxs} {
        font-size: 16px;
      }
    } // .calculator
  }

  .subheading {
    font-size: 30px;
    @media #{$mxw-md} {
      font-size: 26px;
    }
  }
  .subheading-2 {
    font-size: 26px;
    @media #{$mxw-md} {
      font-size: 21px;
    }
  }
  .flag {
    background-color: $color-1;
    padding: 8px 28px 12px;
    font-family: $font-aldine-italic;
    font-size: 26px;
    @media #{$mxw-sm} {
      font-size: 20px;
    }
  }
  .label {
    font-family: $font-swiss-light;
    text-transform: uppercase;
    font-size: 14px;
    letter-spacing: 1.1px;
  }
}

#mtp-modal .modal-content {
  border-radius: 20px;
  img {
    width: 40px;
  }
}
