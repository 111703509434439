@mixin transition {
  transition: 0.1s all ease-in-out;
}

@mixin flex-grid($cols, $gutter: 0, $mt: $gutter) {
  display: flex;
  flex-wrap: wrap;
  > * {
    margin-left: 0;
    margin-bottom: 0;
    $item-width: 0;
    @if ($gutter > 0) {
      $item-width: calc((100% / #{$cols}) - #{$gutter} + (#{$gutter} / #{$cols}));
    } @else {
      $item-width: calc((100% / #{$cols}));
    }
    margin-right: $gutter;
    width: $item-width;
    box-sizing: border-box;
    &:nth-child(n+#{$cols + 1}) {
      margin-top: $mt;
    }
    &:nth-child(#{$cols}n+1) {
      margin-right: $gutter - 1px;
    }
    &:nth-child(#{$cols}n) {
      margin-right: 0;
    }
  }
}

@mixin section-2-responsive {
  @include flex-grid(1, 0px);
  max-width: 377px;
  .module {
    margin-bottom: 24px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

@mixin terms-faqs-wrap {
  display: flex;
  justify-content: center;
  br {
    display: none;
  }
  a {
    display: inline-block;
  }
  @media #{$mxw-xs} {
    text-align: center;
    display: block;
    .divider {
      display: none;
    }
    br {
      display: inline;
    }
    a:first-of-type {
      margin-bottom: 15px;
    }
  }
}
