.cathay-page {
  .hero {
    background-image: url('#{$imgPath}cathay/hero_d.jpg');
    @media #{$mxw-md} {
      background-image: url('#{$imgPath}cathay/hero_m.jpg');
    }
    .logo {
      bottom: 20px;
    }
  }

  .modules {
    @media #{$mnw-md} {
      @include flex-grid(3, 30px);
    }
  }
  .module {
    box-shadow: $box-shadow-2-hov;
    border-radius: 10px;
    @media #{$mxw-md} {
      width: 250px;
      height: auto;
      margin: 0 12px;
    }
  }
  .img-wrap {
    position: relative;
    p {
      position: absolute;
      bottom: 12px;
      left: 10px;
      color: #fff;
      text-transform: uppercase;
      font-size: 12px;
    }
  }
  .module-1 .wrap img {
    width: 36px;
  }
  .module-2 .wrap img {
    width: 38px;
  }
  .module-3 .wrap img {
    width: 38px;
  }
  .wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .icon-wrap {
    display: flex;
    align-items: center;
    height: 38px;
  }

  .terms-faqs-wrap {
    @include terms-faqs-wrap;
  }
}

#cathay-link-form-page {
  .status-pills {
    @media #{$mnw-lg} {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 5px;
    }
    @media #{$mxw-lg} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-row: auto auto;
      grid-gap: 15px;
      max-width: 500px;
      margin: 0 auto;
      .vertical-border {
        display: none;
      }
    }
    @media #{$mxw-sm} {
      display: flex;
      flex-direction: column;
    }
  }
  .status-wrap {
    display: flex;
    @media #{$mxw-lg} {
      flex-direction: column;
    }
    @media #{$mxw-sm} {
      flex-direction: column;
    }
  }
  .status-pill {
    background-color: $bonvoy-white;
    padding: 6px 18px;
    margin: 0 6px;
    &:nth-of-type(2),
    &:nth-of-type(4) {
      min-width: 240px;
    }
    @media #{$mxw-lg} {
      min-width: auto;
      margin: 0;
      &:nth-of-type(1),
      &:nth-of-type(3) {
        margin-bottom: 15px;
      }
    }
    @media #{$mxw-md} {
      &:nth-of-type(2),
      &:nth-of-type(4) {
        min-width: auto;
      }
    }
    @media #{$mxw-sm} {
      padding: 6px 8px;
    }
    @media #{$mxw-sm} {
      &:nth-of-type(1),
      &:nth-of-type(3) {
        margin-bottom: 0;
        padding: 12px 8px 0px;
      }
      &:nth-of-type(2),
      &:nth-of-type(4) {
        padding: 0px 8px 12px;
      }
    }
  }
  .benefit-cards .front .subheading a {
    display: none;
  }
  .no-link {
    display: inline;
  }
  .vertical-border {
    border-left: 1px solid $gray-70;
    height: 28px;
    margin: 0 20px;
  }
}

#cathay-terms-page,
#singapore-terms-page {
  .section-heading {
    font-family: $font-heading;
    font-size: 20px;
    margin: 40px 0 20px;
  }
  // https://bit.ly/3sQxGY1
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0;
    padding: 0;
    ol,
    ul {
      margin-top: 6px;
    }
  }
  ul {
    list-style: none;
    margin-left: 0;
    li {
      padding-bottom: 14px;
    }
  }
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }
  ol > li:before {
    content: counters(item, '.') '. ';
    display: table-cell;
    padding-right: 0.6em;
  }
  li ol > li {
    margin: 0;
  }
  li ol > li:before {
    content: counters(item, '.') '. ';
  }
}
