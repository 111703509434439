.font-main,
.font-main * {
  font-family: $font-main !important;
}
.font-swiss-light,
.font-swiss-light * {
  font-family: $font-swiss-light !important;
}
.font-swiss-regular,
.font-swiss-regular * {
  font-family: $font-swiss-regular !important;
}
.font-swiss-medium,
.font-swiss-medium * {
  font-family: $font-swiss-medium !important;
}
.font-swiss-italic,
.font-swiss-italic * {
  font-family: $font-swiss-italic !important;
}
.font-swiss-light-italic,
.font-swiss-light-italic * {
  font-family: $font-swiss-light-italic !important;
}
.font-aldine-light,
.font-aldine-light * {
  font-family: $font-aldine-light !important;
}
.font-aldine-italic,
.font-aldine-italic * {
  font-family: $font-aldine-italic !important;
}
.heading-link {
  color: $black;
  &:hover {
    color: $black;
  }
}
.fz-4xl { font-size: 50px !important; }
.fz-3xl { font-size: 40px !important; }
.fz-2xl { font-size: 34px !important; }
.fz-xl { font-size: 24px !important; }
.fz-lg { font-size: 22px !important; }
.fz-md { font-size: 20px !important; }
.fz-sm { font-size: 18px !important; }
.fz-xs { font-size: 16px !important; }
.fz-2xs { font-size: 14px !important; }
.fz-3xs { font-size: 12px !important; }
.fz-4xs { font-size: 10px !important; }
@media #{$mxw-md} {
  .fz-xl { font-size: 20px !important; }
  .fz-md { font-size: 18px !important; }
  .fz-xs { font-size: 14px !important; }
}
.fz-26 { font-size: 26px !important; }
.fz-21-16 {
  font-size: 21px !important;
  @media #{$mxw-md} {
    font-size: 16px !important;
  }
}

.lh-1 { line-height: 1 !important; }

.flex-1-0-auto { flex: 1 0 auto; }
.flex-auto { flex: 1 1 auto; }

.font-subheading {
  font-family: $font-subheading !important;
}
.bolder {
  font-family: $font-swiss-bold;
}
.bold {
  font-family: $font-main-bold;
}
.semibold {
  font-family: $font-main-semibold;
}
.font-alt {
  font-family: $font-alt;
}
.page-padding {
  padding-bottom: 40px;
  @media #{$mxw-md} {
    padding-bottom: 20px;
  }
}
.page-padding-2 {
  padding-bottom: 60px;
  @media #{$mxw-md} {
    padding-bottom: 20px;
  }
}
.label {
  font-family: $font-main;
  font-size: 16px;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.no-wrap {
  white-space: nowrap;
}
.bg-gray {
  background-color: $gray-5;
}
.gray {
  color: $gray-60;
}
.uppercase {
  text-transform: uppercase;
}
[class*='mxw-'] { margin: 0 auto; }
.mxw-body { max-width: 930px; }
.mxw-xl { max-width: 1050px; }
.mxw-lg { max-width: 840px; }
.mxw-md {
  width: 740px;
  max-width: 740px;
  @media #{$mxw-md} {
    width: unset;
  }
}
.mxw-sm { max-width: 640px; }
.mxw-input-wrap {
  max-width: 480px;
  margin: 0 auto;
}
.hide {
  display: none !important;
}
.rounded {
  border-radius: 20px !important;
}
.bg-bonvoy-white {
  background-color: $bonvoy-white;
}
.bg-color-1 {
  background-color: $color-1;
}
.bg-white {
  background-color: #fff !important;
}
.disc {
  list-style: disc;
}
.list-indent-1 {
  padding-left: 40px;
}
.flex-grid-2 {
  @include flex-grid(2);
}

.color-error {
  color: $color-error;
}
