.hero {
  position: relative;
  height: 480px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  @media #{$mxw-sm} {
    height: 350px;
  }
  .logo {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    width: 478px;
    @media #{$mxw-md} {
      width: 308px;
    }
    @media #{$mxw-xxs} {
      width: 80%;
    }
  }
}
.hero-flag,
.img-flag {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  bottom: 0;
  .wrap {
    border-bottom: 45px solid #fff;
    border-left: 45px solid transparent;
    border-right: 45px solid transparent;
    height: 0;
    width: 300px;
  }
  p {
    position: relative;
    top: 10px;
    font-family: $font-aldine-italic;
    font-size: 20px;
    text-align: center;
    @media #{$mxw-sm} {
      font-size: 16px;
      padding: 9px 26px;
    }
  }
}

.property {
  position: absolute;
  bottom: 15px;
  right: 20px;
  color: #fff;
  font-size: 12px;
  text-transform: uppercase;
  @media #{$mxw-sm} {
    width: max-content;
    right: unset;
    left: 50%;
    transform: translateX(-50%);
    bottom: 6px;
    font-size: 10px;
  }
}

.swiper-outer-wrap {
  position: relative;
  padding: 65px 0 0;
  overflow: hidden;
}
.swiper {
  overflow: visible;
}
.swiper-wrapper {
  padding-top: 30px;
}
[class*='swiper-button'] {
  width: 13px;
  height: 8px;
  top: -6px;
  &:after {
    color: $black;
    font-size: 16px;
    font-weight: bold;
  }
}
.swiper-button-prev {
  left: 18px;
}
.swiper-button-next {
  right: 18px;
}
.swiper-pagination {
  top: -40px;
  height: 30px;
}
.swiper-pagination-bullet {
  border: 1px solid $black;
  width: 14px;
  height: 14px;
  opacity: 1;
}
.swiper-pagination-bullet-active {
  background-color: $black;
}
.swipe {
  position: absolute;
  top: -55px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  text-transform: uppercase;
}

.modal-footer {
  border: none;
}

.links {
  display: flex;
  justify-content: center;
  *:last-of-type {
    position: relative;
    margin-left: 30px;
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: -15px;
      width: 1px;
      height: 13px;
      background-color: $black;
    }
  }
  *:first-of-type {
    margin-left: 0;
    &:after {
      display: none;
    }
  }
}

img.icon-accelerate {
  position: absolute;
  left: 50%;
  transform: translate(-50%);
  top: -6px;
  width: 26px;
  z-index: 9;
}

.benefit-cards {
  margin: 0 auto;
}
.benefit-cards-amount-1 {
  max-width: 374px;
  .flip-container {
    width: 100%;
  }
}
.benefit-cards-amount-2 {
  @include flex-grid(2, 27px);
  max-width: 775px;
}
.benefit-cards-amount-3 {
  @include flex-grid(3, 27px);
  @media #{$mxw-lg} {
    @include flex-grid(1, 0px);
    max-width: 500px;
    > * {
      margin-bottom: 20px;
    }
  }
}
.benefit-cards-amount-4 {
  @include flex-grid(2, 27px);
  max-width: 775px;
}
.benefit-cards-amount-2,
.benefit-cards-amount-4 {
  @media #{$mxw-md} {
    @include flex-grid(1, 0px);
    max-width: 500px;
    > * {
      margin-bottom: 20px;
    }
  }
}
.benefit-card {
  position: relative;
  background-color: $bonvoy-white;
  padding: 30px 20px;
  .subheading,
  p {
    text-align: center;
  }
  .subheading {
    padding: 0 20px;
    @media #{$mxw-md} {
      padding: 0;
    }
  }
}

.flip-container:hover .flipper,
.flip-container.hover .flipper {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
}
.flip-container.flip .flipper,
.flip-container.flip .flipper {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
}
.flip-container.border {
  border: none !important;
  .flipper {
    border: 1px solid $coral;
  }
}
.flip-container {
  .front,
  .back {
    .wrap {
      margin-bottom: 65px;
    }
  }
}
.flip-container.flip {
  .front {
    position: absolute;
    opacity: 0;
    z-index: -1;
    .flip-directions {
      pointer-events: none;
    }
  }
  .back {
    opacity: 1;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
.front {
  position: relative;
  opacity: 1;
  width: 100%;
  height: 100%;
}
.back {
  opacity: 0;
  ul {
    text-align: left;
    margin-left: 10px;
  }
}
.flip-directions {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  font-family: $font-swiss-medium;
  color: $bonvoy;
  img {
    width: 24px;
    margin-top: 5px;
  }
  * {
    pointer-events: none;
  }
}

.terms-page {
  .section-heading {
    font-family: $font-main-bold;
    font-size: 16px;
    margin: 20px 0 12px;
  }
}

.promo-rab {
  display: inline-block;
  max-width: 758px;
  margin: 0 auto;
  border-radius: 9px;
  box-shadow: $box-shadow-2;
  * {
    color: $black;
  }
  .img-wrap {
    position: relative;
    .flag {
      display: flex;
      justify-content: center;
      position: absolute;
      width: 100%;
      bottom: -20px;
      > * {
        font-family: $font-aldine-italic;
        padding: 10px 25px;
        background-color: $color-1;
      }
    }
  }
  .copy-wrap {
    padding: 40px 40px 30px;
    @media #{$mxw-md} {
      padding: 40px 20px 30px;
    }
  }
  .subheading-2 {
    font-size: 26px;
  }
}

.faqs-list {
  margin-left: 0;
  > li {
    list-style: none;
  }
  .qa-wrap {
    border-bottom: 1px solid $gray;
    &:last-of-type {
      border: none;
    }
  }
  .collapse-trigger {
    padding: 15px 0;
    @media #{$mxw-sm} {
      padding: 13px 0;
    }
  }
}

.no-link {
  display: none;
}

#creative-page {
  padding-top: 60px;
  background-color: #fff;
  min-height: 100vh;
  .container {
    max-width: $page-max-width;
  }
	.heading-wrap {
		display: flex;
		align-items: flex-end;
		border-bottom: 1px solid $black;
		margin-bottom: 20px;
		padding-bottom: 12px;
    .site-name {
      font-size: 16px;
      margin: 0;
    }
    p {
      font-size: 24px;
      justify-self: flex-end;
    }
    img.mbv-icon {
      width: 18px;
      height: 18px;
      margin: 1px 8px 0 0;
    }
    img.flag {
      width: auto;
      height: 15px;
      margin-top: 3px;
      box-shadow: 2px 1px 6px $gray-dk;
    }
  }
  .subheading {
    font-family: $font-subheading;
    font-size: 26px;
  }
  .subheading-2 {
    font-family: $font-aldine-italic;
    color: $gray-60;
    font-size: 20px;
  }
  .list-heading {
    font-family: $font-main-semibold;
    font-size: 22px;
    margin-bottom: 20px;
  }
  [class*='column-'] {
    width: 300px;
  }
  .wrap {
    display: flex;
    justify-content: space-between;
    width: 100%;
		> * {
			flex: 1 1 50%;
		}
	}
	.flex-column {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
  .lang-col {
    flex: 0 0 200px;
    width: 200px;
  }
  .partner-select {
    border-bottom: 1px solid $gray;
    li {
      display: inline-block;
      margin-right: 24px;
      &:last-of-type {
        margin-right: 0;
      }
    }
    button {
      padding: 6px 18px 7px;
      border: 1px solid $black;
      border-radius: 20px;
      font-family: $font-swiss-medium;
      transition: 0.1s all ease-in-out;
      &:hover {
        background-color: $gray-20;
      }
      &.highlight {
        background-color: $black;
        color: #fff;
        &:hover {
          background-color: $black;
        }
      }
    }
  }
	a {
		position: relative;
		display: inline-block;
		font-family: $font-main;
		font-size: 18px;
		color: $black;
		margin-bottom: 12px;
		&:after {
			content: '';
			position: absolute;
      bottom: auto;
      left: auto;
      width: auto;
      height: 0;
      transform: translateX(5px);
		}
		&:hover {
      text-decoration: none;
			font-family: $font-main-semibold;
			&:after {
				content: '\027A4';
			}
		}
	}
	.section {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		border-bottom: 1px solid $gray-20;
		margin-bottom: 12px;
		&:last-of-type {
			border-bottom: none;
		}
	}
	.gray {
    color: $gray-60;
  }
}

#splash-page-ie {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: $bonvoy-white;
  overflow-y: scroll;
  z-index: 99999;
  .wrap {
    margin: 100px auto 0;
    padding-bottom: 50px;
    max-width: 800px;
    img {
      width: 140px;
    }
    .browsers {
      display: flex;
      justify-content: center;
    }
    .browser {
      padding: 0 30px;
      text-align: center;
      a {
        text-align: center;
        color: #000;
      }
      img {
        width: auto;
        height: 50px;
        margin-bottom: 10px;
      }
      .link-arrow {
        display: block;
      }
    }
  }
  @media #{$mxw-sm} {
    .wrap {
      margin: 40px auto 0;
    }
    .browsers {
      flex-direction: column;
      align-items: center;
    }
    .browser {
      margin-bottom: 45px;
      &:last-of-type {
        margin-bottom: 0;
      }
      img {
        height: 35px;
      }
      .link-arrow {
        display: inline-block;
      }
    }
  }
}
